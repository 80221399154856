<template>
  <div class="loginfullscreen is-flex is-flex-direction-column is-justify-content-center">
    <h1 class="m-3">Signup</h1>

    <div class="is-flex is-flex-direction-column is-align-content-center m-2 is-90-pc-width">

        <input class="input mt-2" type="text" placeholder="username" ref="username">
        <input class="input mt-2" type="email" placeholder="email" ref="email">
        <input class="input mt-2" type="password" placeholder="password" ref="password0">
        <input class="input mt-2" type="password" placeholder="repeat password" ref="password" @keypress.enter="handleSubmit">

        <Captcha @changed="handleCaptchaChanged"/>

        <button v-if="buttonActive" class="button mt-3 has-background-dark has-text-light" @click="handleSubmit">SIGN UP</button>

    </div>

  </div>
</template>

<script>
import Captcha from "@/components/ItemForm/CaptchaClientOnly.vue"; // change to Captcha.vue after issue with node canvas resolved

import { useStore } from "vuex";
import {ref } from "@vue/runtime-core";

export default {
  name: 'Signup',
  components:{Captcha},
  setup(){

      const store = useStore();

      const username = ref(null);
      const email = ref(null);
      const password0 = ref(null);
      const password = ref(null);
      const buttonActive = ref(null);

      let captcha;

      const handleCaptchaChanged = async(input)=>{
        console.log("captcha changed",input);
        if (input.length === 7) buttonActive.value = true;
        else buttonActive.value = false;
        captcha = input
      }

      const handleSubmit = async ()=>{
        if (password0.value.value != password.value.value){
          return store.dispatch("triggerToast",{message:'passwords do not match'})
        }
        if (password.value.value.length < 8){
          return store.dispatch("triggerToast",{message:'password must be at least 8 characters long'})
        }
        let res = store.dispatch("signup",{
              username:username.value.value,
              password:password.value.value,
              email:email.value.value,
              captcha:captcha
        });
        if (res.data && res.data.userAdded){
          username.value.value = null;
          password.value.value = null;
          password0.value.value = null;
          email.value.value = null;
        }
      }

      return {
          handleSubmit,
          username,
          password,
          password0,
          email,
          buttonActive,handleCaptchaChanged,
     }
  }
}
</script>

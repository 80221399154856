<template>
  <div class="loginfullscreen is-flex is-flex-direction-column is-justify-content-center">
    <h1 class="m-3">Forgot Password</h1>

    <div class="is-flex is-flex-direction-column is-align-content-center m-2 is-90-pc-width">

        <input class="input mt-2" type="text" placeholder="username" ref="username">
        <input class="input mt-2" type="email" placeholder="email" ref="email">
       
        <button class="button mt-3 has-background-dark has-text-light" @click="handleSubmit">REQUEST NEW PASSWORD LINK</button>

    </div>

  </div>
</template>

<script>

import { useStore } from "vuex";
import {ref } from "@vue/runtime-core";

export default {
  name: 'ForgotPassword',
  emits:["done"],
  setup(props,{emit}){

      const store = useStore();

      const username = ref(null);
      const email = ref(null);

      const handleSubmit = async ()=>{
        
        let res = await store.dispatch("forgotPassword",{
              user:username.value.value,
              email:email.value.value,
        });
        if (res.data){
          store.dispatch("triggerToast",{
            message: process.env.VUE_APP_FORGOTPASSWORD_TEXT || 
            'request send. depending on your system configuration, you or your admin will get a restore link.'})
          emit('done')
        }
      }

      return {
          handleSubmit,
          username,
          email
     }
  }
}
</script>

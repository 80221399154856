<template>
  <Login />
  <keep-alive include="SearchItems">
    <component :is="mainComponent"/>
  </keep-alive>
  <TabBar/>
</template>

<script>
// import { onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'
import {useToast} from 'vue-toastification'
import { ref } from '@vue/reactivity';
import { useRouter } from 'vue-router';

export default {
  setup() {
    
    const store = useStore();
    const toast = useToast();

    const router = useRouter();

    router.beforeEach((to) => {
      if (to.name == "SearchItems"){
        changeMainComponent('SearchItems')
        return
      }
      else
      {
        changeMainComponent('router-view')
      }
      return
    })

    let mainComponent = ref("router-view");

    const changeMainComponent = function(e){
      //  if (e == "SearchItems"){
      //   router.push("/SearchItems")
      // }
      mainComponent.value = e;
    }

    store.dispatch('testconnection')
    
    let lastToastmessage = "1234"
    document.addEventListener("triggerToast",(e)=>{
      if (e.detail.toastmessage == lastToastmessage){
        toast.clear();
      }
      if(e.detail.toasttype){
        toast[e.detail.toasttype](e.detail.toastmessage);
      }
      else{
        toast(e.detail.toastmessage)
      }
      
      lastToastmessage = e.detail.toastmessage;
    })


    // store all basket changes into localStorage.basket (here so it is active before first load of basket view)
    store.subscribe((mutation, state) => {
      if (state?.data?.basket) {
        localStorage.setItem('basket',JSON.stringify(state.data.basket))
      }
    })

    return {
      mainComponent,
      changeMainComponent
    }
  },
}
</script>

<style>


</style>
